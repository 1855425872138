import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";
import { createBreakpoint } from "react-use";

// Context
import { PageColorConsumer } from "../components/context/page-color";
import { PageTypeConsumer } from "../components/context/page-type";

const Page = styled.div`
  & .heading {
    margin: 0 0 10px 0;
  }

  & a {
    &:hover {
      font-family: "RotationLTStd-Italic";
    }
  }

  @media (max-width: 768px) {
    .mobile-max-width {
      max-width: 550px;
      margin: 0 auto;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.desktopCol};

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
  }
`;

const GridCol = styled.div`
  width: 100%;
  padding: 40px 20px;

  &.scrollable {
    overflow: scroll;
    height: 100vh;

    @media (max-width: 768px) {
      height: auto;
    }
  }

  &:first-of-type {
    border-right: 1px solid #555;

    @media (max-width: 768px) {
      border: 0;
    }
  }

  & .text-container {
    max-width: 550px;
    margin: 0 auto;

    & .nhg-title {
      margin: 0 0 25px 0;
    }

    & .profile-text {
      margin: 40px 0 36px 0;

      & p {
        margin: 0;
      }

      @media (max-width: 768px) {
        margin: 0 0 0 0;
        padding: 20px 0 14px 0;

        & .nhg-title {
          margin: 0 0 6px 0 !important;

          & h1,
          & h2 {
            font-family: "neue-haas-grotesk-text", sans-serif;
            font-weight: 700;
            font-style: normal;

            font-size: 12px;
            line-height: 10px;

            letter-spacing: 0.03em;
            text-transform: uppercase !important;
          }
        }
      }
    }

    & .image-container {
      margin: 0 0 40px 0;
    }

    @media (max-width: 768px) {
      max-width: 100%;

      & .profile-text {
        text-align: left;
      }
    }
  }

  & .contact-text {
    margin: 40px 0 36px 0;

    & p {
      margin: 0;
    }

    @media (max-width: 768px) {
      margin: 0 0 0 0;
      padding: 20px 0 14px 0;

      & .nhg-title {
        margin: 0 0 6px 0 !important;

        & h1,
        & h2 {
          font-family: "neue-haas-grotesk-text", sans-serif;
          font-weight: 700;
          font-style: normal;

          font-size: 12px;
          line-height: 10px;

          letter-spacing: 0.03em;
          text-transform: uppercase !important;
        }
      }
    }
  }

  & .imprint {
    margin: 40px 0 0 0;
    padding: 40px 0 0 0;

    border-top: 1px solid #555;

    & .nhg-title {
      margin: 0 0 25px 0;
    }

    & p {
      margin: 0;
    }

    @media (max-width: 768px) {
      margin: 0 0 0 0;
      padding: 20px 0 14px 0;

      & .nhg-title {
        margin: 0 0 6px 0 !important;

        & h3 {
          font-family: "neue-haas-grotesk-text", sans-serif;
          font-weight: 700;
          font-style: normal;

          font-size: 12px;
          line-height: 10px;

          letter-spacing: 0.03em;
          text-transform: uppercase !important;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 30px 10px 0 10px;

    &:last-of-type {
      padding: 0 10px;
    }
  }
`;

const AdditionalTextBlock = styled.div`
  border-bottom: 1px solid #555;

  padding: 40px 0 34px 0;

  &:first-of-type {
    padding: 0 0 34px 0;
  }

  & p:first-of-type {
    margin-top: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & .nhg-title {
    margin: 0 0 25px 0;
  }

  &:last-of-type {
    border-bottom: 0;
    padding: 40px 0 0 0;
  }

  @media (max-width: 768px) {
    padding: 20px 0 14px 0;

    &:last-of-type {
      padding: 20px 0 14px 0;
    }

    & .nhg-title {
      margin: 0 0 6px 0 !important;

      & h2 {
        font-family: "neue-haas-grotesk-text", sans-serif;
        font-weight: 700;
        font-style: normal;

        font-size: 12px;
        line-height: 10px;

        letter-spacing: 0.03em;
        text-transform: uppercase !important;
      }
    }
  }
`;

const useBreakpoint = createBreakpoint();

const ProfileContent = ({ data, setPageColor, setPageType }) => {
  const breakpoint = useBreakpoint();

  useEffect(() => {
    setPageColor(`Green`);
    setPageType("practice");
  }, []);

  useEffect(() => {
    if (breakpoint === "tablet") {
      document.body.style.overflow = "";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [breakpoint]);

  const rightColumnText = data.prismicProfile.data.additional_text.map(
    (content, index) => (
      <AdditionalTextBlock key={`additional_text_block_right_column_${index}`}>
        <div className="mobile-max-width">
          {" "}
          <div className="text-container">
            <div
              className="nhg-title"
              dangerouslySetInnerHTML={{
                __html: content.additional_text_block_title.html,
              }}
            />

            <div
              dangerouslySetInnerHTML={{
                __html: content.additional_text_block.html,
              }}
            />
          </div>
        </div>
      </AdditionalTextBlock>
    )
  );

  const leftColumnText = data.prismicProfile.data.left_column_text.map(
    (content, index) => (
      <AdditionalTextBlock key={`additional_text_block_left_column_${index}`}>
        <div className="mobile-max-width">
          <div className="text-container">
            <div
              className="nhg-title"
              dangerouslySetInnerHTML={{
                __html: content.left_column_text_block_title.html,
              }}
            />

            <div
              dangerouslySetInnerHTML={{
                __html: content.left_column_text_block.html,
              }}
            />
          </div>
        </div>
      </AdditionalTextBlock>
    )
  );

  return (
    <Page>
      <Helmet title="Profile – PUP ARCHITECTS" />

      <Grid desktopCol={`1fr 1fr`}>
        <GridCol className="scrollable">
          <div className="text-container">
            <div className="image-container">
              {data.prismicProfile.data.image.fluid !== null && (
                <img
                  srcSet={data.prismicProfile.data.image.fluid.srcSetWebp}
                  src={data.prismicProfile.data.image.fluid.srcWebp}
                  alt={data.prismicProfile.data.image.alt}
                  loading="lazy"
                />
              )}
            </div>
          </div>

          <hr />
          <div className="mobile-max-width">
            <div className="text-container">
              <div className="contact-text">
                <div className="nhg-title">
                  <h2>Contact</h2>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.prismicContact.data.contact_text.html,
                  }}
                />
              </div>
            </div>
          </div>
          <hr />

          <div className="text-container">
            <div className="mobile-max-width">
              <div className="profile-text">
                <div className="nhg-title">
                  <h1>Pup Architects</h1>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.prismicProfile.data.text.html,
                  }}
                />
              </div>
            </div>
          </div>

          <hr />
          <div>{leftColumnText}</div>
        </GridCol>

        <GridCol className="scrollable">
          <div>{rightColumnText}</div>

          <div>
            <div className="imprint">
              <div className="mobile-max-width">
                <div className="text-container">
                  <p>
                    Design and Identity:{" "}
                    <a
                      href="https://www.wolfehall.com"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Wolfe Hall
                    </a>
                  </p>
                  <p>
                    Website Development:{" "}
                    <a
                      href="https://www.kieranstartup.co.uk"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Kieran Startup
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </GridCol>
      </Grid>
    </Page>
  );
};

const Profile = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <PageColorConsumer>
        {({ setPageColor }) => (
          <ProfileContent
            setPageColor={setPageColor}
            setPageType={setPageType}
            data={data}
          />
        )}
      </PageColorConsumer>
    )}
  </PageTypeConsumer>
);

export const query = graphql`
  {
    prismicProfile {
      uid
      id
      data {
        image {
          alt
          fluid {
            srcSetWebp
            srcWebp
          }
        }
        left_column_text {
          left_column_text_block_title {
            html
          }
          left_column_text_block {
            html
          }
        }
        text {
          html
        }
        additional_text {
          additional_text_block_title {
            html
          }
          additional_text_block {
            html
          }
        }
      }
    }
    prismicContact {
      data {
        contact_text {
          html
        }
        social_media_links {
          html
        }
      }
    }
  }
`;
export default withPreview(Profile);
